import { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { getBuyerId, getUserType } from 'dibs-cookie-jar';
import { addLoginHandlers } from 'dibs-buyer-layout/src/utils/loginHandlers';
import * as tracking from 'dibs-tracking';
import { mobile } from 'dibs-client-check';
import { useIntl } from 'dibs-react-intl';
import { HomePageContext } from './HomePageContext';
import { connectContext } from '../context/helpers/connectContext';
import { getItemsPerPage } from './helpers/getItemsPerPage';
import getLazyLoadStartingPoint from './helpers/getLazyLoadStartingPoint';
import { HpSharedAppBannerLazy } from './HpSharedAppBanner/HpSharedAppBannerLazy';
import { HpSharedCompleteEmailOptinConfirmationModalLazy } from './HpSharedCompleteEmailOptinConfirmationModal/HpSharedCompleteEmailOptinConfirmationModalLazy';
import { trackSellerBrandingRemovalAbTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';
import {
    getUserSessionCountryCode,
    getUserZipCode,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import { getLocalRecentHistoryList } from 'dibs-recent-history/exports/getLocalRecentHistoryList';
import { componentModuleMap } from './componentModuleMap';

const HpSharedLayoutComponent = props => {
    const intl = useIntl();
    const [userId, setUserId] = useState(null);
    const [userType, setUserType] = useState(null);
    const [isClient, setIsClient] = useState(null);
    const [fetchFavorites, setFetchFavorites] = useState(false);

    const { relay, isLoggedInUser, viewer, isMobile, isTablet, homepageUserGroup } = props;
    const itemsPerPage = getItemsPerPage({ isMobile, isTablet });

    const user = viewer?.user || null;

    const doRefetch = useCallback(
        (additionalVariables = {}) => {
            const localRecentHistoryList = getLocalRecentHistoryList().map(item => ({
                itemId: item.itemId,
                timestamp: item.timestamp,
            }));

            const recentActivityDesignVariant = isMobile ? 'mobile' : 'default';

            relay.refetch(
                fragmentVariables => ({
                    ...fragmentVariables,
                    fetchInitialHomepage: false,
                    isClient: true,
                    isMobile,
                    hasUserId: !!userId,
                    userId: userId || '',
                    userIds: userId ? [userId] : [],
                    userType: homepageUserGroup || userType || '',
                    userCountryCode: getUserSessionCountryCode(),
                    zipCode: getUserZipCode(),
                    localRecentHistoryList,
                    recentActivityDesignVariant,
                    ...additionalVariables,
                }),
                { fetchInitialHomepage: true },
                () => {
                    setIsClient(true);

                    // ensure that we are done with refetch before considering to allow favorites fetch
                    if (userId) {
                        setFetchFavorites(true);
                    }
                }
            );
        },
        [userId, userType, relay, isMobile, homepageUserGroup]
    );

    const setUserData = useCallback(() => {
        const cookieUserId = getBuyerId(document.cookie);

        if (cookieUserId && !userId) {
            // order is important to prevent unnecessary refetch, read more: https://github.com/1stdibs/ferrum/pull/13601#pullrequestreview-818832289
            setUserType(getUserType(document.cookie));
            setUserId(cookieUserId);
        }
    }, [userId]);

    useEffect(() => {
        // Remove 'seller-directory-redirect=true' check, when 'SellerBrandRemoval' abTest is over
        const params = new URLSearchParams(window.location.search);
        const sellerDirectoryRedirect = params.get('seller-directory-redirect');
        const opt_pageURL =
            sellerDirectoryRedirect === 'true' ? '/?seller-directory-redirect=true' : '/';
        tracking.trackPageview({ additional: { opt_pageURL } });

        trackSellerBrandingRemovalAbTestVariant();
    }, []);

    useEffect(() => {
        if (!isLoggedInUser && !isClient) {
            doRefetch();
        }
    }, [doRefetch, isLoggedInUser, isClient]);

    useEffect(() => {
        if (userId && !user) {
            doRefetch();
        }
    }, [doRefetch, userId, user]);

    useEffect(() => {
        setUserData();
        addLoginHandlers(data => {
            if (data?.user?.isVerifiedTrade) {
                // keep this to make sure a trade member
                // logging in on the hp is "redirected"
                // to the trade homepage
                window.location.reload(true);
                return;
            }
            setUserData();
        });
    }, [setUserData, intl.locale]);

    const { homepage = {} } = viewer;

    const isUser = isLoggedInUser || userId; // isLoggedInUser comes at load; userId might come later from refetch

    const { personalization = null, modules } = homepage;
    const promoPrimaryModule = modules.find(mod => mod.type === 'promo' && mod.index === 0);
    const promoPrimaryItemsCount = (promoPrimaryModule?.messages?.items || []).length;
    const startLazyLoadAt = getLazyLoadStartingPoint({ isMobile, isTablet });
    const hasLoggedInWithApp = user?.hasLoggedInWithApp;

    const mods = modules.map((mod, index) => {
        let { type } = mod;

        if (type === 'promo') {
            type = mod.index === 0 ? 'promoPrimary' : 'promoSecondary';
        }

        const component = componentModuleMap[type];
        if (component) {
            return component({
                componentModule: mod,
                key: `${type}-${index}`,
                promoPrimaryItemsCount,
                viewer,
                isMobile,
                isTablet,
                itemsPerPage,
                isUser,
                personalization,
                useLazyLoadImages: index >= startLazyLoadAt,
                moduleIndex: index,
                totalModules: modules.length,
                userId: userId,
                environment: relay.environment,
                fetchFavorites,
            });
        }
        return null;
    });

    return (
        <Fragment>
            {mods}
            {isClient && <HpSharedCompleteEmailOptinConfirmationModalLazy />}
            {isClient &&
                (mobile.iPhone() || mobile.iPad()) &&
                !(isLoggedInUser && hasLoggedInWithApp) && <HpSharedAppBannerLazy />}
        </Fragment>
    );
};

HpSharedLayoutComponent.propTypes = {
    homepageUserGroup: PropTypes.string,
    isLoggedInUser: PropTypes.bool,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    relay: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
};

function mapContextToProps({ isLoggedInUser, isMobile, isTablet }) {
    return {
        isMobile,
        isTablet,
        isLoggedInUser,
    };
}

const HpSharedLayoutWithContext = connectContext(
    HomePageContext,
    mapContextToProps
)(HpSharedLayoutComponent);

export const HpSharedLayout = createRefetchContainer(
    HpSharedLayoutWithContext,
    {
        viewer: graphql`
            fragment HpSharedLayout_viewer on Viewer {
                homepage(rootId: $homepageId, abTestList: $abTestList) {
                    modules {
                        ... on SkinnyBannerModule {
                            type
                        }
                        # don't skip this module on refetch, otherwise the dibs-carousel within
                        # won't get any items
                        ... on TopHeroBannerModule {
                            type
                            ...HpSharedTopHeroBannerModule_componentModule
                        }
                        ... on HeroModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedHeroModule_componentModule
                        }
                        ... on CollectionsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedCollectionsModule_componentModule
                        }
                        ... on FeaturedModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedFeaturedModule_componentModule
                        }
                        ... on PromoModule {
                            index
                            type
                            messages(userType: $userType) {
                                items {
                                    cmsId
                                }
                            }
                            ...HpSharedPromoModulePrimary_componentModule
                            ...HpSharedPromoModuleSecondary_componentModule
                        }
                        ... on EditorialModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedEditorialModule_componentModule
                        }
                        ... on ShopByModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedShopByModule_componentModule
                        }
                        ... on LocationsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedLocationsModule_componentModule
                        }
                        ... on ArrivalsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedPersonalizedCategories_componentModule
                        }
                        ... on RecentlyViewedModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedRecentlyViewedItems_componentModule
                        }
                        ... on RecentActivityModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedRecentActivityModule_componentModule
                        }
                        ... on RecommendedItemsModule @include(if: $fetchInitialHomepage) {
                            type
                            ...HpSharedRecommendedItems_componentModule
                        }
                        ... on CollectionSpotlightModule {
                            type
                            ...HpSharedCollectionSpotlightModule_componentModule
                        }
                    }
                    personalization(userId: $userId, first: 3, numItems: 3)
                        @include(if: $hasUserId) {
                        ...HpSharedPersonalizedCategories_personalization
                    }
                }
                ...HpSharedHeroModule_viewer
                ...HpSharedRecentActivityModule_viewer
                ...HpSharedRecommendedItems_viewer
                user(userId: $userId) @include(if: $hasUserId) {
                    hasLoggedInWithApp: hasLoggedInWithDeviceType(deviceType: IOS_APP)
                }
            }
        `,
    },
    graphql`
        query HpSharedLayoutRefetchQuery(
            $hasUserId: Boolean!
            $userId: String!
            $homepageId: String!
            $isMobile: Boolean!
            $fetchInitialHomepage: Boolean!
            $localRecentHistoryList: [LocalRecentHistoryListInputType] = []
            $recentActivityDesignVariant: String = "default"
            $userType: String!
            $abTestList: [String] = []
        ) {
            viewer {
                ...HpSharedLayout_viewer
            }
        }
    `
);
