import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ItemTile } from 'dibs-search-product-tile/exports/ItemTile';
import { Maybe } from 'dibs-ts-utils/exports/Maybe';
import { RecommendedItemHeart } from '../RecommendedItemHeart/RecommendedItemHeart';
import { ItemClickProps, ItemContentVisibleProps } from '../types';

import { RecommendedItem_viewer$key } from './__generated__/RecommendedItem_viewer.graphql';
import { RecommendedItem_item$key } from './__generated__/RecommendedItem_item.graphql';

import styles from '../RecommendedItemsCarousel/RecommendedItemsCarousel.scss';

export type Item = RecommendedItem_item$key | null | undefined;

export type Props = {
    viewer: RecommendedItem_viewer$key;
    item: Item;
    userId: string;
    index: number;
    isMobile: boolean;
    itemsPerPage: number;
    itemsIds: Maybe<string>[];
    showTitle?: boolean;
    onItemClick?: (props: ItemClickProps) => void;
    onItemContentVisible?: (props: ItemContentVisibleProps) => void;
    onFavorited?: ({ itemId }: { itemId: string | null }) => void;
    onUnFavorited?: ({ itemId }: { itemId: string | null }) => void;
};

const viewerFragment = graphql`
    fragment RecommendedItem_viewer on Viewer
    @argumentDefinitions(
        userIds: { type: "[String]", defaultValue: [] }
        loadPortfolioData: { type: "Boolean", defaultValue: false }
    ) {
        ...RecommendedItemHeart_viewer
            @arguments(userIds: $userIds, loadPortfolioData: $loadPortfolioData)
    }
`;

const itemFragment = graphql`
    fragment RecommendedItem_item on Item {
        serviceId
        ...recommendedItemTracking_item
        ...ItemTile_item @arguments(showShippingBadge: false)
    }
`;

const MOBILE_ITEMS_PER_PAGE = 2.5;

export const RecommendedItem: FC<Props> = ({
    viewer: viewerRef,
    item: itemRef,
    userId,
    index,
    isMobile,
    itemsPerPage,
    itemsIds,
    showTitle = true,
    onItemContentVisible = () => {},
    onItemClick = () => {},
    onFavorited,
    onUnFavorited,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const item = useFragment(itemFragment, itemRef);

    if (!item) {
        return null;
    }

    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemInner} key={`${index}-${item.serviceId}`}>
                <ItemTile
                    index={index}
                    item={item}
                    itemsPerRow={isMobile ? MOBILE_ITEMS_PER_PAGE : itemsPerPage}
                    showSellerName={false}
                    showCreatorName={false}
                    showTitle={showTitle}
                    showPrice={false}
                    showShippingBadge={false}
                    isMobile={isMobile}
                    disableBorder
                    useLoFiLazyLoader
                    showQuickView={false}
                    hideImagePadding
                    viewer={null}
                    imageSize="imageSmall"
                    showMSKUSwatch={false}
                    showMeasurements={false}
                    itemSearch={null}
                    onContentVisible={() =>
                        onItemContentVisible({
                            index,
                            item,
                        })
                    }
                    onClick={() => {
                        onItemClick({
                            item,
                            index,
                        });
                    }}
                />
                <RecommendedItemHeart
                    itemId={item.serviceId}
                    viewer={viewer}
                    itemsIds={itemsIds}
                    className={styles.heart}
                    buttonClass={styles.heartButton}
                    userId={userId}
                    onFavorited={onFavorited}
                    onUnFavorited={onUnFavorited}
                />
            </div>
        </div>
    );
};
